@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.mobile-container {
  color: white;
}

.nav-bar {
  position: sticky;
  max-width: 100%;
  height: 100%;
  background: black;
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ee20ba11;
  background-image: url(/public/images/Carlos_0760.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover; /* Ensures the image covers the entire footer */
}

.nav-bar a {
  text-decoration: none;
  color: rgb(26, 13, 13);
}
.nav-bar h2 {
  font-size: 8px;
  padding: 5px;
  text-align: center;
  color: #b61a1a;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.nav-bar input[type="text"] {
  float: right;
  padding: 6px;
  border: none;
  margin-top: 8px;
  margin-right: 16px;
  font-size: 17px;
}

.userLogin {
  color: #0a0505;
  text-align: center;
  max-width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
}

.nav-bag {
  color: #b61a1a;
  text-align: center;
  max-width: 10%;
  font-size: 10px;
  display: flex;
  padding-top: 0%;
  left: 50%;
}

@media only screen and (min-width: 600px) {
  .nav-bar {
    position: sticky;
    max-width: 100%;
    height: 100%;
    background: black;
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_0760.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover; /* Ensures the image covers the entire footer */
  }

  .nav-bar a {
    text-decoration: none;
    color: rgb(26, 13, 13);
  }
  .nav-bar h2 {
    font-size: 8px;
    padding: 5px;
    text-align: center;
    color: #b61a1a;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .nav-bar input[type="text"] {
    float: right;
    padding: 6px;
    border: none;
    margin-top: 8px;
    margin-right: 16px;
    font-size: 17px;
  }

  .userLogin {
    color: #0a0505;
    text-align: center;
    max-width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
  }

  .nav-bag {
    color: #b61a1a;
    text-align: center;
    max-width: 10%;
    font-size: 10px;
    display: flex;
    padding-left: 100%;
    padding-top: 0%;
    right: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .nav-bar {
    position: sticky;
    max-width: 100%;
    height: 70%;
    background: black;
    display: block;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_0760.jpg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover; /* Ensures the image covers the entire footer */
  }

  .nav-bar a {
    text-decoration: none;
    color: rgb(26, 13, 13);
  }
  .nav-bar h2 {
    font-size: 20px;
    text-align: center;
    color: #b61a1a;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }

  .nav-bar input[type="text"] {
    float: right;
    padding: 6px;
    border: none;
    margin-top: 8px;
    margin-right: 16px;
    font-size: 17px;
  }

  .userLogin {
    color: #0a0505;
    text-align: center;
    max-width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: space-around;
  }

  .nav-bag {
    color: #b61a1a;
    text-align: center;
    max-width: 10%;
    font-size: 10px;
    display: block;
    padding-left: 90%;
    padding-top: 0%;
    left: 10%;
  }
}

.menu {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

@media only screen and (max-width: 600px) {
  .menu-links {
    display: none;
  }

  .menu-links.active {
    display: block;
  }
}

.home-container {
  align-items: center;
  max-width: 100%;
  text-align: center;
  background-color: #ee20ba11;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-container img {
  max-width: 100%;
}

.home-container h2 {
  font-size: 20px;
  color: rgba(63, 37, 37, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

@media only screen and (min-width: 600px) {
  .home-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
    .home-container img {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .home-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
  }

  .home-container img {
    max-width: 100%;
  }
}

.hero-image {
  position: relative;
  max-width: 100%;
  height: 100%;
}

.hero-text {
  max-width: 100%;
  text-align: center;
  position: absolute;
  padding-top: 10%;
  gap: 10%;
  top: -8%;
  right: 60%;
}

.hero-text h3 {
  font-size: 10px;
  color: rgba(63, 37, 37, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}
.hero-text p {
  font-size: 7px;
  color: rgba(7, 6, 6, 0.925);
  padding-left: 13%;
  padding-right: 13%;
}
.hero-text h2 {
  font-size: 10px;
  color: rgba(49, 24, 24, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

@media only screen and (min-width: 600px) {
  .hero-image {
    position: relative;
    max-width: 100%;
    height: 100%;
    opacity: 0.9;
  }

  .hero-text {
    max-width: 100%;
    text-align: center;
    position: absolute;
    padding-top: 10%;
    gap: 10%;
    top: 25%;
    left: 0%;
  }

  .hero-text h3 {
    font-size: 30px;
    color: rgba(43, 11, 11, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }
  .hero-text p {
    font-size: 15px;
    color: rgb(15, 7, 5);
    padding-left: 13%;
    padding-right: 13%;
    font-style: italic;
  }
  .hero-text h2 {
    font-size: 20px;
    color: rgba(20, 3, 3, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }
}

@media only screen and (min-width: 1024px) {
  .hero-image {
    position: relative;
    max-width: 100%;
    height: 100%;
    opacity: 0.9;
  }

  .hero-text {
    max-width: 100%;
    text-align: center;
    position: absolute;
    padding-top: 10%;
    gap: 10%;
    top: 20%;
    left: 20%;
  }

  .hero-text h3 {
    font-size: 30px;
    color: rgba(43, 11, 11, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }
  .hero-text p {
    font-size: 15px;
    color: rgb(15, 7, 5);
    font-style: italic;
    padding-left: 36%;
    justify-content: left;
  }
  .hero-text h2 {
    font-size: 20px;
    color: rgba(20, 3, 3, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
    padding-left: 33%;
  }
}

.products button:hover {
  background-color: #cc0d269f; /* Green */
  color: white;
}

.details {
  font-size: 15px;
  color: rgba(160, 25, 25, 0.562);
}

.products {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
  grid-gap: 20px; /* Gap between grid items */
  align-items: center;
}

.products button {
  background-color: #5c494988; /* Green */
  border-radius: 15px;
  border: 2px solid #423d3e;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
}

.products button:hover {
  background-color: #cc0d269f; /* Green */
  color: white;
}

@media only screen and (min-width: 600px) {
  .products {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
    grid-gap: 20px; /* Gap between grid items */
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .products {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(4, 1fr); /* Three columns with equal width */
    grid-gap: 20px; /* Gap between grid items */
    align-items: center;
  }
}

.products > div {
  position: relative; /* Set position to relative */
}

.products img {
  width: 100%; /* Ensure the image takes up the full width of its parent */
  display: block;
}

.products-text {
  position: absolute; /* Position the text absolutely */
  top: 50%; /* Align to the vertical center */
  left: 50%; /* Align to the horizontal center */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust position to center both vertically and horizontally */
  text-align: center; /* Center-align text */
  color: rgb(15, 3, 3);
  font-size: 20px;
}

.footer {
  background-color: #ee20ba11;
  background-image: url(/public/images/Carlos_9.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Ensures the image covers the entire footer */
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-content {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  grid-gap: 20px; /* Gap between grid items */
  gap: 1em;
  text-align: center;
}

.footer-content h2 {
  font-size: 17px;
  color: #423d3e;
}

.footer-content p {
  font-size: 10px;
}

.social-icons-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust the value as needed */
  opacity: 70%;
  transition: opacity 1s ease-in-out;
  font-size: 12px;
  color: #b61a1a;
}

.footer-section-links {
  justify-content: center;
  color: rgba(126, 14, 14, 0.699);
  font-size: 12px;
}

.Partnership {
  text-align: center;
  color: rgba(42, 78, 177, 0.993);
  font-size: 9px;
}

.register-container {
  padding: 0%;
}

.login-container {
  padding: 0%;
  padding-top: 10%;
  padding-bottom: 40%;
 
}

/* Responsive design adjustments to Footer */
@media only screen and (min-width: 600px) {
  .footer {
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_9.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the entire footer */
    display: block;
    opacity: 0.9;
  }

  .footer-content {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
    grid-gap: 20px; /* Gap between grid items */
    gap: 1em;
    text-align: center;
  }

  .footer-content h2 {
    font-size: 17px;
    color: #423d3e;
  }

  .footer-content p {
    font-size: 10px;
  }

  .social-icons-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the value as needed */
    opacity: 70%;
    transition: opacity 1s ease-in-out;
    font-size: 12px;
    color: #b61a1a;
  }

  .footer-section-links {
    justify-content: center;
    color: rgba(126, 14, 14, 0.699);
    font-size: 15px;
    gap: 10%;
  }
  .Partnership {
    text-align: center;
    color: rgba(42, 78, 177, 0.993);
    font-size: 9px;
  }
}

/* Responsive design adjustments */
@media only screen and (min-width: 1024px) {
  .footer {
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_9.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; /* Ensures the image covers the entire footer */
    display: block;
    opacity: 0.9;
  }

  .footer-content {
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
    grid-gap: 20px; /* Gap between grid items */
    gap: 1em;
    text-align: center;
  }

  .footer-content h2 {
    font-size: 17px;
    color: #423d3e;
  }

  .footer-content p {
    font-size: 10px;
  }

  .social-icons-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the value as needed */
    opacity: 70%;
    transition: opacity 1s ease-in-out;
    font-size: 12px;
    color: #b61a1a;
  }

  .footer-section-links {
    justify-content: center;
    color: rgba(126, 14, 14, 0.699);
    font-size: 15px;
    gap: 10%;
  }
  .Partnership {
    text-align: center;
    color: rgba(42, 78, 177, 0.993);
    font-size: 9px;
  }
}

.product-overlay {
  position: relative; /* Position the overlay relative to its children */
  width: 100%; /* Ensure the overlay takes up the full width of its parent */
}

.product-image {
  width: 100%; /* Ensure the image takes up the full width of its parent */
  display: block;
}

.product-details {
  position: relative; /* Position the details absolutely within the overlay */
  color: white; /* Ensure the text is visible against the image */
  text-align: center; /* Center-align text */
  width: 100%; /* Ensure the details take up the full width of its parent */
  font-size: 9px;
}

.product-details .details {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  gap: 10px; /* Space between items */
}

.product-details .details .price {
  font-size: 24px; /* Increase font size for visibility */
  font-weight: bold; /* Make the price stand out */
}

.product-details button {
  background-color: #5c494988; /* Green */
  border-radius: 15px;
  border: 2px solid #423d3e;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-top: 5px; /* Add some space above the button */
  padding: 8px 16px; /* Adjust padding for better touch target size */
}



/* Product Design */

.ProductDetails {
  max-width: 100%;
  font-size: 20px;
  text-align: center;
  background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
  padding: 5%;
}

.ProductDetails img {
  position: relative;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
}

.ProductDetails h3 {
  font-size: 40px;
  color: rgba(73, 49, 49, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

.ProductDetails p {
  font-size: 20px;
  color: rgba(163, 45, 15, 0.925);
  padding-left: 13%;
  padding-right: 13%;
}

.ProductDetails button {
  background-color: #5c494988; /* Green */
  border-radius: 15px;
  border: 2px solid #423d3e;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  margin-top: 5px; /* Add some space above the button */
  padding: 8px 16px; /* Adjust padding for better touch target size */

}
/* using Min- 1024 */

@media only screen and (min-width: 600px) {
  /* Product Design */

  .ProductDetails {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .ProductDetails img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .ProductDetails h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .ProductDetails p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .ProductDetails button {
    background-color: #5c494988; /* Green */
    border-radius: 15px;
    border: 2px solid #423d3e;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    margin-top: 5px; /* Add some space above the button */
    padding: 8px 16px; /* Adjust padding for better touch target size */
  }
  /* using Min- 1024 */
}

@media only screen and (min-width: 1024px) {
  .ProductDetails {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .ProductDetails img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .ProductDetails h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .ProductDetails p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .ProductDetails button {
    background-color: #5c494988; /* Green */
    border-radius: 15px;
    border: 2px solid #423d3e;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    margin-top: 5px; /* Add some space above the button */
    padding: 8px 16px; /* Adjust padding for better touch target size */
  }
}


.RegForm {
  padding: 20px;
  text-align: center;
}

.RegForm input[type="text"],
.RegForm input[type="email"],
.RegForm input[type="password"],
.RegForm input[type="streetNr"],
.RegForm input[type="zipCode"],
.RegForm input[type="Country"],
.RegForm input[type="Code"],
.RegForm input[type="phoneNumber"]
 {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.RegForm input[type="submit"],
.RegForm button {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.RegForm input[type="submit"]:hover,
.RegForm button:hover {
  background-color: #45a049;
}

.RegForm .error {
  color: red;
}

.RegForm label {
  display: block;
  margin-bottom: 10px;
}

.RegForm input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle;
}


@media only screen and (min-width: 600px) {
  .RegForm {
    padding: 20px;
    text-align: center;
  }
  
  .RegForm input[type="text"],
.RegForm input[type="email"],
.RegForm input[type="password"],
.RegForm input[type="streetNr"],
.RegForm input[type="zipCode"],
.RegForm input[type="Country"],
.RegForm input[type="Code"],
.RegForm input[type="phoneNumber"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .RegForm input[type="submit"],
  .RegForm button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .RegForm input[type="submit"]:hover,
  .RegForm button:hover {
    background-color: #45a049;
  }
  
  .RegForm .error {
    color: red;
  }
  
  .RegForm label {
    display: block;
    margin-bottom: 10px;
  }
  
  .RegForm input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
  }
  
}

@media only screen and (min-width: 1024px) {
  .RegForm {
    padding: 20px;
    text-align: center;
  }
  
  .RegForm input[type="text"],
  .RegForm input[type="email"],
  .RegForm input[type="password"],
  .RegForm input[type="streetNr"],
  .RegForm input[type="zipCode"],
  .RegForm input[type="Country"],
  .RegForm input[type="Code"],
  .RegForm input[type="phoneNumber"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .RegForm input[type="submit"],
  .RegForm button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .RegForm input[type="submit"]:hover,
  .RegForm button:hover {
    background-color: #45a049;
  }
  
  .RegForm .error {
    color: red;
  }
  
  .RegForm label {
    display: block;
    margin-bottom: 10px;
  }
  
  .RegForm input[type="checkbox"] {
    margin-right: 10px;
    vertical-align: middle;
  }
  
}

/*Event 1024*/

.events-container {
  align-items: center;
  max-width: 100%;
  text-align: center;
  background-color: #ee20ba11;
  background-image: url(/public/images/Carlos_0760.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.events {
  max-width: 100%;
  font-size: 20px;
  text-align: center;
  background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
  padding: 5%;
}

.events img {
  position: relative;
  max-width: 100%;
  height: 100%;
  border-radius: 20px;
}

.events h3 {
  font-size: 40px;
  color: rgba(73, 49, 49, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
}

.events p {
  font-size: 20px;
  color: rgba(163, 45, 15, 0.925);
  padding-left: 13%;
  padding-right: 13%;
}

.events button {
  border: none;
  color: rgb(17, 6, 6);
  padding: 16px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid #04aa6d;
  background-color: #aa048185;
  border-radius: 5px;
}

/*Event 1024*/
@media only screen and (min-width: 1024px) {
  .events-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_0760.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .events {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .events img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .events h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .events p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .events button {
    border: none;
    color: rgb(17, 6, 6);
    padding: 16px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border: 2px solid #04aa6d;
    background-color: #aa048185;
    border-radius: 5px;
  }
}

/*Event 1024*/
@media only screen and (min-width: 600px) {
  .events-container {
    align-items: center;
    max-width: 100%;
    text-align: center;
    background-color: #ee20ba11;
    background-image: url(/public/images/Carlos_0760.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .events {
    max-width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: #6e0f0f11; /* Ensures the image covers the entire footer */
    padding: 5%;
  }

  .events img {
    position: relative;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  .events h3 {
    font-size: 40px;
    color: rgba(73, 49, 49, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
  }

  .events p {
    font-size: 20px;
    color: rgba(163, 45, 15, 0.925);
    padding-left: 13%;
    padding-right: 13%;
  }

  .events button {
    border: none;
    color: rgb(17, 6, 6);
    padding: 16px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border: 2px solid #04aa6d;
    background-color: #aa048185;
    border-radius: 5px;
  }
}

/*About Page*/

.about-background-image {
  /* Styles for the background image container */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
}

.about-container {
  /* Styles for the container holding the about content */
  padding: 20px;
  color: #fff; /* Assuming you want white text for readability */
  max-width: 800px; /* Limit the width for better readability */
  margin: 0 auto; /* Center the container */
}

.about-heading {
  /* Styles for the headings */
  font-size: 2em;
  margin-bottom: 10px;
}

.about-text {
  /* Styles for the text content */
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.map-container {
  /* Styles for the map container */
  margin-top: 20px;
  width: 100%;
  height: 450px;
}

.map-container iframe {
  /* Styles for the iframe within the map container */
  width: 100%;
  height: 100%;
  border: none;
}

/*About Page 600px*/
@media only screen and (min-width: 600px) {
  .about-background-image {
    /* Styles for the background image container */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
  }

  .about-container {
    /* Styles for the container holding the about content */
    padding: 20px;
    color: #fff; /* Assuming you want white text for readability */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
  }

  .about-heading {
    /* Styles for the headings */
    font-size: 2em;
    margin-bottom: 10px;
  }

  .about-text {
    /* Styles for the text content */
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .map-container {
    /* Styles for the map container */
    margin-top: 20px;
    width: 100%;
    height: 450px;
  }

  .map-container iframe {
    /* Styles for the iframe within the map container */
    width: 100%;
    height: 100%;
    border: none;
  }
}

/*About Page 1024px*/
@media only screen and (min-width: 1024px) {
  .about-background-image {
    /* Styles for the background image container */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
  }

  .about-container {
    /* Styles for the container holding the about content */
    padding: 20px;
    color: #fff; /* Assuming you want white text for readability */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the container */
  }

  .about-heading {
    /* Styles for the headings */
    font-size: 2em;
    margin-bottom: 10px;
  }

  .about-text {
    /* Styles for the text content */
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .map-container {
    /* Styles for the map container */
    margin-top: 20px;
    width: 100%;
    height: 450px;
  }

  .map-container iframe {
    /* Styles for the iframe within the map container */
    width: 100%;
    height: 100%;
    border: none;
  }
}

/*Contact-container*/

/* Container for the entire contact section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

/* Form container */
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

/* Input fields and textarea */
.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit button */
.form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Reach out and legal stuff containers */
.reach-out-container,
.legal-stuff-container,
.terms-conditions-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  align-items: center;
}

/* Headings */
.contact-container h1,
.reach-out-container h2,
.legal-stuff-container h2,
.terms-conditions-container h3 {
  color: #333;
  margin-bottom: 10px;
}

/* Paragraphs */
.contact-container p,
.reach-out-container p,
.legal-stuff-container p,
.terms-conditions-container p {
  color: #666;
  margin-bottom: 10px;
}

/*Contact Page 600px*/
@media only screen and (min-width: 600px) {
  /* App.css */

  /* Container for the entire contact section */
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
  }

  /* Form container */
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  /* Input fields and textarea */
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  /* Submit button */
  .form-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Reach out and legal stuff containers */
  .reach-out-container,
  .legal-stuff-container,
  .terms-conditions-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  /* Headings */
  .contact-container h1,
  .reach-out-container h2,
  .legal-stuff-container h2,
  .terms-conditions-container h3 {
    color: #333;
    margin-bottom: 10px;
  }

  /* Paragraphs */
  .contact-container p,
  .reach-out-container p,
  .legal-stuff-container p,
  .terms-conditions-container p {
    color: #666;
    margin-bottom: 10px;
  }
}

/*About Page 1024px*/
@media only screen and (min-width: 1024px) {
  /* App.css */

  /* Container for the entire contact section */
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
  }

  /* Form container */
  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
  }

  /* Input fields and textarea */
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  /* Submit button */
  .form-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Reach out and legal stuff containers */
  .reach-out-container,
  .legal-stuff-container,
  .terms-conditions-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  /* Headings */
  .contact-container h1,
  .reach-out-container h2,
  .legal-stuff-container h2,
  .terms-conditions-container h3 {
    color: #333;
    margin-bottom: 10px;
  }

  /* Paragraphs */
  .contact-container p,
  .reach-out-container p,
  .legal-stuff-container p,
  .terms-conditions-container p {
    color: #666;
    margin-bottom: 10px;
  }
}

/* Cart provided View in the view is for phones */
.cart-container {
  padding: 2rem 4rem;
}
.cart-container h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.cart-container .titles {
  margin: 2rem 0 1rem 0;
}
.cart-container .titles h3 {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}
.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}
.cart-container .titles .product-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
}
.cart-item .cart-product {
  display: block;
}
.cart-item .cart-product img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}
.cart-item .cart-product h3 {
  font-weight: 400;
  font-size: 10px;
  color: rgba(43, 11, 11, 0.836);
}

.cart-item .cart-product p {
  font-weight: 400;
  font-size: 10px;
  color: rgba(43, 11, 11, 0.836);
  font-weight: 1000;
  font-style: italic;
  font-stretch: expanded;
  padding-bottom: 10%;
}
.cart-item .cart-product button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: gray;
}
.cart-item .cart-product button:hover {
  color: black;
}

.cart-item .cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}

.cart-item .cart-product-price {
  padding-right: 0.5rem;
  justify-self: center;
  font-weight: 700;
}

.cart-item .cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}
.cart-item .cart-product-quantity .count {
  padding: 0.7rem 0;
}
.cart-item .cart-product-total-price {
  padding-right: 0.5rem;
  justify-self: right;
  font-weight: 700;
}

/* cart summary */
.cart-summary {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}
.cart-summary .clear-cart {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
  justify-content: space-between;
}
.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}
.cart-checkout .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  background-color: #161719;
  color: #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout .cart-login {
  background-color: rgba(255, 51, 0, 0.736);
  color: rgba(0, 0, 0, 0.769);
}

.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}
.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  /* Cart provided View in the view is for phones */
  .cart-container {
    padding: 2rem 4rem;
  }
  .cart-container h2 {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
  .cart-container .titles {
    margin: 2rem 0 1rem 0;
  }
  .cart-container .titles h3 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .cart-item,
  .cart-container .titles {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
  }
  .cart-item {
    border-top: 1px solid rgb(187, 187, 187);
    padding: 1rem 0;
  }
  .cart-container .titles .product-title {
    padding-left: 0.5rem;
  }
  .cart-container .titles .total {
    padding-right: 0.5rem;
    justify-self: right;
  }
  .cart-item .cart-product {
    display: block;
  }
  .cart-item .cart-product img {
    width: 100px;
    max-width: 100%;
    margin-right: 1rem;
  }
  .cart-item .cart-product h3 {
    font-weight: 400;
    font-size: 10px;
    color: rgba(43, 11, 11, 0.836);
  }

  .cart-item .cart-product p {
    font-weight: 400;
    font-size: 10px;
    color: rgba(43, 11, 11, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
    padding-bottom: 10%;
  }
  .cart-item .cart-product button {
    border: none;
    outline: none;
    margin-top: 0.7rem;
    cursor: pointer;
    background: none;
    color: gray;
  }
  .cart-item .cart-product button:hover {
    color: black;
  }

  .cart-item .cart-product-quantity {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 130px;
    max-width: 100%;
    border: 0.5px solid rgb(177, 177, 177);
    border-radius: 5px;
  }

  .cart-item .cart-product-price {
    padding-right: 0.5rem;
    justify-self: center;
    font-weight: 700;
  }

  .cart-item .cart-product-quantity button {
    border: none;
    outline: none;
    background: none;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
  }
  .cart-item .cart-product-quantity .count {
    padding: 0.7rem 0;
  }
  .cart-item .cart-product-total-price {
    padding-right: 0.5rem;
    justify-self: right;
    font-weight: 700;
  }

  /* cart summary */
  .cart-summary {
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid rgb(187, 187, 187);
    padding-top: 2rem;
  }
  .cart-summary .clear-cart {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.15px;
    border: 0.5px solid rgb(177, 177, 177);
    color: gray;
    background: none;
    outline: none;
    cursor: pointer;
    justify-content: space-between;
  }
  .cart-checkout {
    width: 270px;
    max-width: 100%;
  }
  .cart-checkout .subtotal {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }
  .cart-checkout .amount {
    font-weight: 700;
  }
  .cart-checkout p {
    font-size: 14px;
    font-weight: 200;
    margin: 0.5rem 0;
  }
  .cart-checkout button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.15px;
    background-color: #161719;
    color: #f9f9f9;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .cart-checkout .cart-login {
    background-color: rgba(255, 51, 0, 0.736);
    color: rgba(0, 0, 0, 0.769);
  }

  .continue-shopping,
  .start-shopping {
    margin-top: 1rem;
  }
  .continue-shopping a,
  .start-shopping a {
    color: gray;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .continue-shopping a span,
  .start-shopping a span {
    margin-left: 0.5rem;
  }
  .cart-empty {
    font-size: 20px;
    margin-top: 2rem;
    color: rgb(84, 84, 84);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  /* Cart provided View in the view is for phones */
  .cart-container {
    padding: 2rem 4rem;
  }
  .cart-container h2 {
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
  .cart-container .titles {
    margin: 2rem 0 1rem 0;
  }
  .cart-container .titles h3 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .cart-item,
  .cart-container .titles {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    column-gap: 0.5rem;
  }
  .cart-item {
    border-top: 1px solid rgb(187, 187, 187);
    padding: 1rem 0;
  }
  .cart-container .titles .product-title {
    padding-left: 0.5rem;
  }
  .cart-container .titles .total {
    padding-right: 0.5rem;
    justify-self: right;
  }
  .cart-item .cart-product {
    display: block;
  }
  .cart-item .cart-product img {
    width: 100px;
    max-width: 100%;
    margin-right: 1rem;
  }
  .cart-item .cart-product h3 {
    font-weight: 400;
    font-size: 10px;
    color: rgba(43, 11, 11, 0.836);
  }

  .cart-item .cart-product p {
    font-weight: 400;
    font-size: 10px;
    color: rgba(43, 11, 11, 0.836);
    font-weight: 1000;
    font-style: italic;
    font-stretch: expanded;
    padding-bottom: 10%;
  }
  .cart-item .cart-product button {
    border: none;
    outline: none;
    margin-top: 0.7rem;
    cursor: pointer;
    background: none;
    color: gray;
  }
  .cart-item .cart-product button:hover {
    color: black;
  }

  .cart-item .cart-product-quantity {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 130px;
    max-width: 100%;
    border: 0.5px solid rgb(177, 177, 177);
    border-radius: 5px;
  }

  .cart-item .cart-product-price {
    padding-right: 0.5rem;
    justify-self: center;
    font-weight: 700;
  }

  .cart-item .cart-product-quantity button {
    border: none;
    outline: none;
    background: none;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
  }
  .cart-item .cart-product-quantity .count {
    padding: 0.7rem 0;
  }
  .cart-item .cart-product-total-price {
    padding-right: 0.5rem;
    justify-self: right;
    font-weight: 700;
  }

  /* cart summary */
  .cart-summary {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid rgb(187, 187, 187);
    padding-top: 2rem;
  }
  .cart-summary .clear-cart {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.15px;
    border: 0.5px solid rgb(177, 177, 177);
    color: gray;
    background: none;
    outline: none;
    cursor: pointer;
    justify-content: space-between;
  }
  .cart-checkout {
    width: 270px;
    max-width: 100%;
  }
  .cart-checkout .subtotal {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }
  .cart-checkout .amount {
    font-weight: 700;
  }
  .cart-checkout p {
    font-size: 14px;
    font-weight: 200;
    margin: 0.5rem 0;
  }
  .cart-checkout button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.15px;
    background-color: #161719;
    color: #f9f9f9;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .cart-checkout .cart-login {
    background-color: rgba(255, 51, 0, 0.736);
    color: rgba(0, 0, 0, 0.769);
  }

  .continue-shopping,
  .start-shopping {
    margin-top: 1rem;
  }
  .continue-shopping a,
  .start-shopping a {
    color: gray;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .continue-shopping a span,
  .start-shopping a span {
    margin-left: 0.5rem;
  }
  .cart-empty {
    font-size: 20px;
    margin-top: 2rem;
    color: rgb(84, 84, 84);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
